import React from 'react';
import LayoutComponent from '../components/Layout';

const Test = () => {
  return (
    <LayoutComponent>
      <div style={{ height: '200vh' }}>
        <div style={{ padding: 16, position: 'sticky' }}>hello</div>
      </div>
    </LayoutComponent>
  );
};

export default Test;
